function myFunction() {
    document.getElementById("languageDropdown").classList.toggle("show");
}

window.onclick = function(event) {
    if (!event.target.matches('.language__btn')) {

        var dropdowns = document.getElementsByClassName("language-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if (openDropdown.classList.contains('show')) {
                openDropdown.classList.remove('show');
            }
        }
    }
};

$(document).ready(function () {
    $('.js-hamburger').click(() => {
        $('body').toggleClass('is-open');
        $('html').toggleClass('no-scroll');
    });

    $('.navigation a').on('click', function() {
        $('body').removeClass('is-open');
        $('html').removeClass('no-scroll');
        let href = $(this).attr('href');

        $('html, body').animate({
            scrollTop: $(href).offset().top - 50
        }, {
            duration: 470,
            easing: 'linear'
        });

        return false;
    });

});
